import { t } from '@/i18n/translation_util';
import { getCheckoutAmplitudeABTest } from '@/lib/amplitude-analytics';

export function PricingLinkABTest() {
  const href =
    getCheckoutAmplitudeABTest() == 'checkout-new'
      ? `/checkout${window.location.search}` // TODO: l(`/checkout${window.location.search}`) when we have checkout pages for each language
      : `#checkout`;

  return (
    <>
      <a
        href={href}
        className="relative font-[500] leading-[24px] text-[#171717] no-underline lg:text-[16px]"
      >
        {t('Pricing')}
      </a>
    </>
  );
}
